import { render, staticRenderFns } from "./PartnerLogos.vue?vue&type=template&id=4728a107&scoped=true"
import script from "./PartnerLogos.vue?vue&type=script&lang=js"
export * from "./PartnerLogos.vue?vue&type=script&lang=js"
import style0 from "./PartnerLogos.vue?vue&type=style&index=0&id=4728a107&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4728a107",
  null
  
)

export default component.exports